<template>
  <div>
    <app-collapse
      accordion
      type="margin"
    >
      <app-collapse-item
        class="text-left"
        title="Application Editor"
      >
        <b-overlay
          :show="$apollo.loading || mutationLoading"
          rounded="sm"
        >
          <b-table
            :fields="['round', {key: 'title', thStyle: 'width: 100%'}, 'status','actions']"
            :items="rounds"
            borderless
            responsive
            show-empty
            striped
          >
            <template #cell(round)="row">
              {{ row.item.round_number }}
            </template>
            <template #cell(title)="row">

              <b-form-input
                v-model="row.item.title"
                :readonly="row.item.status !== 'draft'"
                :state="row.item.title? null : false"
                placeholder="Round Title"
              />
            </template>
            <template #cell(status)="row">
              <b-badge
                v-if="!row.item.id"
                variant="light-danger"
              >
                Unsaved
              </b-badge>
              <b-badge
                v-else
                :variant="statusVariant(row.item.status)"
                style="text-transform: capitalize;"
              >
                {{ row.item.status }}
              </b-badge>

            </template>
            <template
              #cell(actions)="row"
            >
              <b-button-group
                size="sm"
                style="white-space: nowrap"
              >
                <b-button
                  v-if="row.item.id"
                  :to="{ name: 'skill-dev-application-question', params: { pid: $route.params.id, apid: row.item.id }, query: {readonly: 'true'} }"
                  size="sm"
                  variant="outline-primary"
                >
                  <feather-icon icon="EyeIcon" />
                  Preview
                </b-button>
                <b-button
                  v-if="row.item.status === 'draft'"
                  variant="outline-primary"
                  @click="selectedRound = row.index; $bvModal.show('appModal')"
                >
                  <feather-icon icon="Edit2Icon" />
                  Edit Form
                </b-button>

                <b-button
                  v-if="row.item.status === 'draft'"
                  size="sm"
                  variant="primary"
                  @click="updateApplication(row.index)"
                >
                  <feather-icon
                    icon="SaveIcon"
                    class="mr-25"
                  />
                  <span v-if="row.item.id">Update</span>
                  <span v-else>Save</span>
                </b-button>
              </b-button-group>
            </template>
          </b-table>
        </b-overlay>
        <b-button
          :disabled="!!rounds.length && rounds[rounds.length-1]['id'] === undefined"
          class="mt-1"
          variant="primary"
          @click="addRound()"
        >
          <feather-icon
            class="mr-25"
            icon="PlusIcon"
          />
          Add Round
        </b-button>
      </app-collapse-item>
      <app-collapse-item
        class="text-left"
        title="Call for Applications"
      >
        <b-overlay
          :show="$apollo.loading || mutationLoading"
          rounded="sm"
        >
          <b-table
            :fields="['ID', 'round', { key: 'application', label: 'application form' }, { key: 'review', label: 'review form' }]"
            :items="rounds.filter(round => round.id !== undefined)"
            borderless
            responsive
            show-empty
            striped
          >
            <template #cell(ID)="row">
              {{ row.index + 1 }}
            </template>
            <template #cell(round)="row">
              {{ row.item.title }}
            </template>
            <template
              #cell(application)="row"
            >
              <span>
                <feather-icon
                  v-b-tooltip="'Applications are '+row.item.status"
                  icon="CircleIcon"
                  class="mr-50"
                  fill="currentColor"
                  :class="row.item.status==='enabled'?'text-success':'text-danger'"
                />
              </span>
              <b-button
                :variant="row.item.status === 'enabled' ? 'danger' : 'primary'"
                size="sm"
                @click="updateApplicationStatus(row.item.status, row.item.id, `status`)"
              ><span v-if="row.item.status === 'enabled'">Disable</span><span v-else>Enable</span>
              </b-button>
            </template>
            <template
              #cell(review)="row"
            >
              <span>
                <feather-icon
                  v-b-tooltip="'Review is '+row.item.review_status"
                  icon="CircleIcon"
                  class="mr-50"
                  fill="currentColor"
                  :class="row.item.review_status==='enabled'?'text-success':'text-danger'"
                />
              </span>
              <b-button
                :variant="row.item.review_status === 'enabled' ? 'danger' : 'primary'"
                size="sm"
                @click="updateApplicationStatus(row.item.review_status, row.item.id, `review_status`)"
              ><span v-if="row.item.review_status === 'enabled'">Disable</span><span v-else>Enable</span>
              </b-button>
            </template>
          </b-table>
        </b-overlay>
      </app-collapse-item>
      <!--      <app-collapse-item-->
      <!--        title="Target Audience"-->
      <!--      >-->
      <!--        <b-button-->
      <!--          class="mx-50"-->
      <!--          variant="outline-primary"-->
      <!--        >-->
      <!--          Import from Networks-->
      <!--        </b-button>-->
      <!--        or-->
      <!--        <b-button-->
      <!--          class="mx-50"-->
      <!--          variant="outline-success"-->
      <!--        >-->
      <!--          <feather-icon-->
      <!--            class="mr-25"-->
      <!--            icon="UploadIcon"-->
      <!--          />-->
      <!--          Upload CSV-->
      <!--        </b-button>-->
      <!--      </app-collapse-item>-->
    </app-collapse>
    <b-overlay
      :show="$apollo.loading"
      rounded="sm"
    >
      <b-card
        class="mt-2 text-left"
        title="View Applicants"
      >
        <!-- search input -->
        <div class="custom-search d-flex justify-content-start">
          <b-form-group
            class="form-inline"
            label="Search"
            label-size="sm"
          >
            <b-form-input
              v-model="searchTerm"
              class="d-inline-block mr-1"
              placeholder="Search applicants"
              type="text"
            />
          </b-form-group>

        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :pagination-options="{
            enabled: true,
            perPage:pageLength,
            position: 'top'
          }"
          :rows="filteredRows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Status -->
            <span
              v-if="props.column.id === 'round' && props.row.programs_applicationformstable"
            ><b-badge
               v-b-tooltip="'Round Number'"
               class="mr-50 mb-25"
               variant="primary"
             >{{ props.row.programs_applicationformstable.round_number }}</b-badge>
              {{ props.formattedRow[props.column.field] }}
            </span>

            <!-- Column: Status-->
            <span v-else-if="props.column.field === 'status'">
              <b-badge
                :variant="statusVariant(props.row.status)"
              >
                {{ props.row.status || 'In-Process' }}
              </b-badge>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <b-button
                variant="outline-primary"
                size="sm"
                :to="{name: 'skill-dev-view-applications', params: {pid: $route.params.id, aid:props.row.id }}"
              >View Applications</b-button>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template
            slot="column-filter"
            slot-scope="props"
          >
            <span v-if="props.column.id === 'round'"><b-form-select
              v-if="props.column.hasOwnProperty('filterOptions') && props.column.filterOptions.customFilter"
              v-model="filter.round"
              placeholder="-- No Filter --"
              :options="[{text: '-- No Filter --', value: null}, ...roundFilterOptions]"
            /></span>
            <span v-if="props.column.field === 'status'"><b-form-select
              v-if="props.column.hasOwnProperty('filterOptions') && props.column.filterOptions.customFilter"
              v-model="filter.status"
              placeholder="-- No Filter --"
              :options="[{text: '-- No Filter --', value: null}, {text: 'Accepted', value: 'accepted'}, {text: 'Declined', value: 'declined'}, {text: 'In-Process', value: 'in-process'}]"
            /></span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-top"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mb-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of <span class="font-medium-4 ml-25"><b-badge
                  variant="light-primary"
                >{{
                  props.total
                }} entries
                </b-badge></span></span>

              </div>
              <div>
                <b-pagination
                  :per-page="pageLength"
                  :total-rows="props.total"
                  :value="1"
                  align="right"
                  class="mt-50"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </b-overlay>
    <b-modal
      id="appModal"
      ok-only
      size="lg"
      title="Application Form"
    >
      <form-builder
        v-if="selectedRound !== null"
        v-model="rounds[selectedRound].programs_applicationsquestionstables"
        :inherited-sections="rounds[selectedRound].programs_applicationsquestionstables"
      />
    </b-modal>
  </div>
</template>
<script>
import FormBuilder from '@/views/incubator-portal/programs/components/FormBuilder.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BBadge,
  BButton,
  BButtonGroup,
  BCard,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BModal,
  BPagination,
  BTable,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BButton,
    BFormInput,
    BCard,
    BFormGroup,
    AppCollapse,
    AppCollapseItem,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BBadge,
    BModal,
    BTable,
    BButtonGroup,
    FormBuilder,

  },
  data() {
    return {
      mutationLoading: false,
      selectedRound: null,
      pageLength: 5,
      filter: {
        round: null,
        status: null,
      },
      columns: [
        {
          label: 'Applicant',
          field(row) {
            return row.users_associationtable?.users_customuser?.full_name || '-'
          },
        },
        {
          id: 'round',
          label: 'Shortlisting Round',
          field(row) {
            return row.programs_applicationformstable?.title || '-'
          },
          filterOptions: {
            enabled: true, // enable filter for this column
            customFilter: true, // use custom filter component
          },
        },
        {
          label: 'Status',
          field: 'status',
          sortable: false,
          filterOptions: {
            enabled: true, // enable filter for this column
            customFilter: true, // use custom filter component
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      rounds: [],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        draft: 'light-primary',
        enabled: 'light-success',
        disabled: 'light-secondary',
        null: 'light-primary',
        accepted: 'light-success',
        declined: 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    filteredRows() {
      if (!this.filter.round && !this.filter.status) return this.rows
      return this.rows.filter(row => row.programs_applicationformstable)
        .filter(row => (this.filter.round === null || row.programs_applicationformstable.round_number === this.filter.round))
        .filter(row => (this.filter.status === null || !row.programs_applicationformstable.status === this.filter.status || (this.filter.status === 'in-process' && !row.programs_applicationformstable.status)))
    },
    roundFilterOptions() {
      return this.rows.filter(e => e.programs_applicationformstable)
        .sort((a, b) => a.programs_applicationformstable.round_number - b.programs_applicationformstable.round_number)
        .map(row => ({
          text: `Round ${row.programs_applicationformstable.round_number} - ${row.programs_applicationformstable.title}`,
          value: row.programs_applicationformstable.round_number,
        }))
        .filter((e, i, a) => a.findIndex(t => t.value === e.value) === i) // Remove duplicates
    },
  },
  methods: {
    addRound() {
      this.rounds.push({
        title: 'New Round',
        round_number: this.rounds.length + 1,
        status: 'draft',
        review_status: 'draft',
        program_id: this.$route.params.id,
        programs_applicationsquestionstables: [],
      })
    },
    updateApplication(i) {
      const payload = this.rounds[i]

      if (payload.programs_applicationsquestionstables.length) {
        payload.programs_applicationsquestionstables = {
          data: payload.programs_applicationsquestionstables,
          on_conflict: {
            constraint: 'programs_applicationsquestionstable_pkey',
            update_columns: ['question', 'input_type', 'options', 'section'],
          },
        }
      } else {
        delete payload.programs_applicationsquestionstables
      }
      this.mutationLoading = true
      this.$apollo.mutate(({
        mutation: gql`mutation($object: programs_applicationformstable_insert_input!) {
            insert_programs_applicationformstable_one(object: $object, on_conflict: {constraint: programs_applicationformstable_pkey, update_columns: title}) {
              id
            }
          }`,
        variables: {
          object: payload,
        },
        update: () => {
          this.$apollo.queries.rounds.refetch()
          this.mutationLoading = false
        },
      }))
    },
    updateApplicationStatus(status, item, col) {
      const update = status === 'enabled' ? 'disabled' : 'enabled'
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation {
          update_programs_applicationformstable_by_pk(pk_columns: {id: ${item}}, _set: {${col}: ${update}}) {
            id
          }
        }`,
        update: () => {
          this.$apollo.queries.rounds.refetch()
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          programs_applicantstable(where:{program_id: {_eq: ${this.$route.params.id}}}){
            id
            program_id
            status
            current_application_form_id
            users_associationtable {
              users_customuser {
                full_name
              }
            }
            programs_applicationformstable {
              title
              round_number
            }
          }
        }`
      },
      update: data => data.programs_applicantstable,
    },
    rounds: {
      query() {
        return gql`
        {
          programs_applicationformstable(where:{program_id: {_eq: ${this.$route.params.id}}}, order_by: {round_number: asc}){
            id
            round_number
            title
            status
            review_status
            programs_applicationsquestionstables {
              id
              question
              section
              options
              input_type
              is_required
            }
          }
        }`
      },
      update(data) {
        /* eslint-disable no-underscore-dangle, no-param-reassign */
        if (data.programs_applicationformstable.length) {
          data.programs_applicationformstable.forEach(e => {
            if (e.status !== 'draft') {
              delete e.programs_applicationsquestionstables
            }
          })
        }
        return data.programs_applicationformstable
      },
    },
  },
}
</script>
