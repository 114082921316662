<template>
  <section id="card-navigation">
    <b-row>
      <!-- pill tabs inside card -->
      <b-col md="12">
        <b-overlay
          :show="$apollo.loading"
          rounded="sm"
          spinner-variant="primary"
        >
          <b-card
            class="text-center"
          >
            <b-card-header
              class="justify-content-start p-0 pb-1 mb-1 border-bottom"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                @click="$router.back()"
              >
                <feather-icon
                  class="mr-25"
                  icon="ChevronLeftIcon"
                />
              </b-button>
              <h3 class="mb-0 ml-2">
                Manage {{ program.title }}
              </h3>
            </b-card-header>
            <b-tabs
              v-model="activeTab"
              class="h-scroll-tabs"
              nav-class="mb-2"
              pills
              @input="updateHash"
            >
              <b-tab
                active
                class="text-left"
                href="Activities"
                lazy
                title="Calendar"
              >
                <scheduler
                  v-if="!$apollo.loading"
                  :begin_date="program.begin_date"
                />
              </b-tab>
              <b-tab
                class="text-left"
                href="Mentors"
                lazy
                title="Mentors"
              >
                <mentor-manager />
              </b-tab>

              <b-tab
                href="Applications"
                lazy
                title="Applications"
              >
                <application-manager />
              </b-tab>
              <b-tab
                href="Students"
                lazy
                title="Students"
              >
                <b-card
                  class="text-left"
                  title="Students Progress"
                >
                  <student-progress />
                </b-card>
              </b-tab>
              <b-tab
                href="Reviews"
                lazy
                title="Reviews"
              >
                <b-card
                  class="text-left"
                  title="Review Manager"
                >
                  <review-manager />
                </b-card>
              </b-tab>
              <b-tab
                href="Progress"
                lazy
                title="Progress"
              >
                <b-card
                  class="text-left"
                  title="Progress Manager"
                >
                  <progress-stages />
                </b-card>
              </b-tab>
              <b-tab
                href="KnowledgeBase"
                lazy
                title="Knowledge Base"
              >
                <b-card
                  class="text-left"
                  title="Knowledge Base"
                >
                  <knowledge-base />
                </b-card>
              </b-tab>
              <b-tab
                class="text-left"
                href="E-Meet"
                lazy
                title="E-Meet"
              >
                <b-card
                  class="text-left"
                  title="Meeting Manager"
                >
                  <emeet-manager />
                </b-card>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BButton, BCard, BCardHeader, BCol, BRow, BTab, BTabs,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import gql from 'graphql-tag'
import ApplicationManager from '@/views/incubator-portal/programs/pages/students/program-manager/ApplicationManager.vue'
import MentorManager from '@/views/incubator-portal/programs/pages/common/program-manager/MentorManager.vue'
import Scheduler from '@/views/incubator-portal/programs/pages/common/program-manager/Scheduler.vue'
import StudentProgress from '@/views/incubator-portal/programs/pages/students/program-manager/StudentProgress.vue'
import ReviewManager from '@/views/incubator-portal/programs/pages/startups/program-manager/ReviewManager.vue'
import EmeetManager from '@/views/incubator-portal/programs/pages/students/program-manager/EmeetManager.vue'
import KnowledgeBase from '@/views/incubator-portal/programs/pages/common/program-manager/KnowledgeBase.vue'
import ProgressStages from '@/views/incubator-portal/programs/pages/common/program-manager/ProgressStages.vue'

export default {
  components: {
    EmeetManager,
    ReviewManager,
    StudentProgress,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BTab,
    BTabs,
    BButton,
    MentorManager,
    ApplicationManager,
    Scheduler,
    KnowledgeBase,
    ProgressStages,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      program: '',
      activeTab: 0,
      tabs: ['#Activities',
        '#Mentors',
        '#Applications',
        '#Students',
        '#Reviews',
        '#Progress',
        '#KnowledgeBase',
        '#Emeet'],
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.activeTab = this.tabs.findIndex(tab => tab === this.$route.hash)
    })
  },
  methods: {
    updateHash(e) {
      this.$router.replace(this.$route.path + this.tabs[e])
    },
  },
  apollo: {
    program: {
      query() {
        return gql`
        {
        programs_basicinfo_by_pk(id: "${this.$route.params.id}"){
          id
          title
          begin_date
        }
      }`
      },
      update: data => data.programs_basicinfo_by_pk,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
