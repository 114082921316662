<template>
  <b-overlay
    :show="$apollo.loading"
    rounded="sm"
    spinner-variant="primary"
  >
    <!-- search input -->
    <div class="custom-search d-flex justify-content-start">
      <b-form-group
        label="Search"
        label-size="sm"
      >
        <b-form-input
          v-model="searchTerm"
          class="d-inline-block mr-1"
          placeholder="Search startups"
          type="text"
        />
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Common -->
        <span class="d-flex justify-content-between">
          {{ props.formattedRow[props.column.field] }}
          <span>
            <b-button
              size="sm"
              variant="outline-primary"
              class="mr-50"
              :to="{name: 'view-reviews', params: {pid: $route.params.id, aid: props.row.id}}"
            >
              <span>Review Manager</span>
            </b-button>
            <b-button
              size="sm"
              variant="outline-primary"
              class="mr-50"
              :to="{name: 'progress-milestone', params: {pid: $route.params.id, aid: props.row.id}}"
            >
              <span>Milestone Manager</span>
            </b-button>
            <b-button
              size="sm"
              variant="outline-primary"
              :to="{name: 'student-manager', params: {id: props.row.programs_applicantstable.user_association_id}}"
            >
              <span>Student Manager</span>
            </b-button></span>
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :per-page="pageLength"
              :total-rows="props.total"
              :value="1"
              align="right"
              class="mt-1 mb-0"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-overlay>
</template>
<script>
import {
  BButton, BFormGroup, BFormInput, BFormSelect, BPagination,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {

    BFormInput,
    BFormGroup,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BButton,
  },
  data() {
    return {
      searchTerm: '',
      rounds: [
        { title: null },
      ],
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [

        {
          label: 'Name',
          field(row) {
            return row.programs_applicantstable?.users_associationtable?.users_customuser?.full_name || '-'
          },
        },
      ],
      rows: [],
    }
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          programs_startupparticipants(where: {program_id: {_eq: ${this.$route.params.id}}}) {
            id
            programs_applicantstable {
              user_association_id
              users_associationtable {
                users_customuser {
                  full_name
                }
              }
            }
          }
        }`
      },
      update: data => data.programs_startupparticipants,
    },
  },
}
</script>
